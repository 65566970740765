import { InternalServerError } from '@/components'
import '@/styles/globals.css'
import { AppProps } from 'next/app'
import { Suspense } from 'react'
import { PagesProgressBar as ProgressBar } from 'next-nprogress-bar'
import { Hind_Siliguri } from 'next/font/google' // 导入 Hind Siliguri 字体

const hindSiliguri = Hind_Siliguri({
  subsets: ['latin'],
  weight: ['400', '500', '700']
}) // 使用 Hind Siliguri 字体

export default function MyApp({ Component, pageProps }: AppProps): JSX.Element {
  return (
    <Suspense fallback={<InternalServerError />}>
      <div className={hindSiliguri.className}>
        {' '}
        {/* 使用 Hind Siliguri 字体 */}
        <Component {...pageProps} />
      </div>
      <ProgressBar
        height="2px"
        color="#1d4ed8"
        options={{ showSpinner: false }}
        shallowRouting
      />
    </Suspense>
  )
}
